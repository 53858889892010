import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <p>PartnerBrozz</p>
        </div>
        <div className="footer-contact">
          <p>2833 Cricket State 518, Fifth Worth, TX 76107</p>
          <p>Sales Line (+1214-740-6148)</p>
          <p>Support Line (+1817-730-5837)</p>
        </div>
        <div className="footer-links">
          <p>About us</p>
          <p>We're Hiring!</p>
          <p>Blog</p>
          <p>Privacy Policy</p>
        </div>
        <div className="footer-links">
          <p>Schedule a demo</p>
          <p>FAQ's</p>
          <p>Login</p>
          <p>Support</p>
        </div>
        <div className="footer-social">
          <p>Like. Subscribe. Review</p>
          <p>f  o  in  y</p>
        </div>
      </div>
      <div className="footer-copyright">
        <p>© 2023 XSQRD, Inc PartnerBrozz. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
