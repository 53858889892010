import React from 'react';
import './Hero.css';
import heroImage from '../assets/hero-image.png';

function Hero() {
  return (
    <section className="hero">
      <div className="hero-content">
        <div className="hero-referral">
          <span className="hero-referral-icon">
            {/* Placeholder for icon */}
            <i className="fas fa-handshake"></i>
          </span>
          Become a PartnerBrozz referral partner
        </div>
        <h1 className="hero-title">Generate qualified leads and get paid</h1>
        <p className="hero-description">
          Recommend PartnerBrozz to your network of roofing and restoration
          professionals by sharing your unique link, and earn generous residual
          commissions once they convert to customers.
        </p>
        <button className="hero-button">Become a referral partner</button>
      </div>
      <div className="hero-image-container">
        <img src={heroImage} alt="Hero Placeholder" className="hero-image" />
      </div>
    </section>
  );
}

export default Hero;
