import React from 'react';
import './Main.css';
import { FaCheckCircle, FaTruck, FaTools, FaFileInvoiceDollar } from 'react-icons/fa';

const Main = () => {
  return (
    <main className="main-content">
      <section className="features">
        <div className="feature-card">
          <FaCheckCircle className="feature-icon" />
          <h3>Fast Approvals</h3>
          <p>Get your repair approvals faster than ever.</p>
        </div>
        <div className="feature-card">
          <FaTruck className="feature-icon" />
          <h3>Direct Delivery</h3>
          <p>We deliver parts directly to your shop.</p>
        </div>
        <div className="feature-card">
          <FaTools className="feature-icon" />
          <h3>Quality Parts</h3>
          <p>We only provide high-quality, reliable parts.</p>
        </div>
         <div className="feature-card">
          <FaFileInvoiceDollar className="feature-icon" />
          <h3>Easy Invoicing</h3>
          <p>Simple and easy invoicing process.</p>
        </div>
      </section>

      <section className="referral-section">
        <div className="referral-image-container">
          <img src="/images/placeholder-image.png" alt="Placeholder" className="referral-image" />
        </div>
        <div className="referral-content">
          <h2>Who do we want to work with?</h2>
          <p>
            We're interested in forming partnerships with companies or individuals who
            can introduce us to machine repairmen whose cash flow is constrained by the
            slow insurance claim payments process.
          </p>
          <p>Examples of great referral partners:</p>
          <ul>
            <li>Insurance Brokers</li>
            <li>Supplement management companies</li>
            <li>Public adjusters</li>
            <li>Existing PartnerBrozz customers</li>
            <li>Industry consultants</li>
            <li>Industry employees</li>
          </ul>
        </div>
      </section>
    </main>
  );
};

export default Main;
