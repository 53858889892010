import React from 'react';
import './Header.css';
import logo from '../assets/hero-image.png';

function Header() {
  return (
    <header className="header partnerbrozz-header">
      <div className="logo-container">
        <img src={logo} alt="PartnerBrozz Logo" className="logo" />
      </div>
      <nav className="nav">
        <a href="/about">About us</a>
        <a href="/blog">Blog</a>
        <a href="/faq">FAQ's</a>
        <a href="/we-are-hiring">We're hiring</a>
      </nav>
      <div className="header-buttons">
        <button className="phone-button">
          <i className="fas fa-phone-alt"></i> 214-740-6148
        </button>
        <button className="login-button">Login</button>
      </div>
    </header>
  );
}

export default Header;
